<template>
  <div class="forget_box" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="forget_backimg"></div>
    <div class="forget_content">
      <p class="forget_logo">
        <img src="../assets/icon/cc-logo-white.svg" alt />
      </p>
      <div class="forget_infor">
        <div class="forget_fill">
          <p class="forget_title">Forget Your Password</p>
          <p class="tip">Email</p>
          <el-input
            v-model="email"
            @keyup.enter.native="forgetgo"
            placeholder="Please enter your email address"
          ></el-input>
          <div class="forget_btn">
            <el-button class="forget_next" @click="forgetgo()">Submit Password Forget Request</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { apiEmail } from "../API/api";
export default {
  data() {
    return {
      email: "",
      fullscreenLoading: false,
    };
  },
  methods: {
    forgetgo() {
      if (this.email != "") {
        this.fullscreenLoading = true;
        apiEmail({ email: this.email,user_type:'pm' }).then((res) => {
          if (res.state == "success") {
            this.data.email = this.email;
          }
        }).finally(()=>{
           this.fullscreenLoading = false;
        }).catch(err=>{
          this.$message.error(err.response.data&&err.response.data.detail?err.response.data.detail:"Fail in send")
        });
      } else {
        alert("Please enter your email address");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.forget_box {
  width: 100%;
  height: 100vh;
  position: relative;
  .forget_backimg {
    height: 100%;
    width: 100%;
    background-image: url("../assets/img/20191218172529.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    opacity: .8;
    -webkit-filter: blur(5px); /* Chrome, Opera */
    -moz-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(3px);
    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius=10, MakeShadow=false); /* IE6~IE9 */
  }
  .forget_content {
    width: 500px;
    height: 450px;
    position: absolute;
    top: calc(50% - 250px);
    left: calc(50% - 225px);
    .forget_logo {
      text-align: left;
    }
    .forget_infor {
      padding: 40px;
      background-color: #ffffff;
      box-shadow: 0px 5px 20px #33333340;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      .forget_fill {
        width: 500px;
        .forget_btn {
          margin-top: 20px;
        }
      }
    }
    .tip {
      text-align: left;
      margin-bottom: 10px;
      color: #333333;
      font-size: 16px;
    }
    .tip_item{
      margin: 0;
    }
    .forget_title {
      font-size: 34px;
      text-align: left;
      margin: 0;
      color: #678993;
      font-family: PingFangSC-Medium, sans-serif;
    }
    /deep/ .el-input__inner {
      height: 50px;
      line-height: normal;
      background: #fff;
    }
    /deep/ .el-form-item{
      margin-bottom: 10px;
    }
  }
  .el-button {
    background-color: #678993;
    width: 100%;
    color: #ffffff;
    margin-top: 20px;
    font-size: 24px;
  }
  @media (max-width:992px) {
    .forget_content{
       left: 0;
       top: 30px;
       width: 100%;
       height: 100%;
      .forget_infor{
        display: block;
        height: 100%;
        width: 100%;
        padding: 20px 20px 0 20px;
        .forget_fill{width: calc(100% - 40px);}
      }  
      /deep/ .el-input{
          width: 100%;
        }
      .forget_logo{ text-align: center; img{width: 60%;}}
      }
    .forget_backimg{background-size: contain;opacity: .8;}
  }
}
</style>
